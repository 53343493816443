import { useEffect, memo, useMemo } from 'react';
import {
    FormControl,
    InputLabel,
    Typography
} from "@mui/material";
import { sanitize } from "dompurify";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import { useIdle, useUpdateEffect } from 'react-use';

import { FIELD_TYPES, WAITING_TIME } from "@ais/constants";
import { UnderstandingOfEntityProvider } from "@ais/contexts";
import {
    renderLabel,
    CLACheckBoxGroup,
    CLAComboBox,
    CLACurrencyField,
    CLADatePickerField,
    CLADropdown,
    CLAFormTable,
    CLANumberField,
    CLAPercentageField,
    CLARadioButtonList,
    CLASelect,
    copyObject,
    CLAFormula,
    parseQuestionLabel,
    VFQuestionGroup
} from "@ais/forms";

import { Flex } from "@ais/palette";
import { NoValidationInput } from "@components/Common";
import { useProjectFormContext } from "@contexts";
import styles from "./Field.module.css";
import { Link } from '@components/ProjectForm';
import { UOEAuditAreaTable } from '@components/CustomForm';
import { useProjectFormConcurrentLocking } from '@hooks';
import FieldWrapper from '../../Common/FieldWrapper/FieldWrapper';
import { useRules } from '@hooks/useRules';

const ProjectFormTextFields = memo((props) => {
    const { field, handleChange, value, disabled, handleBlur, onFocus } = props;

    let fieldValue = value;

    //only concurrency value from portal has percentage symbol.
    if (field.validation === 'percentage' && isNaN(value) && value?.includes('%')) {
        fieldValue = Number(value.replace('%', '')) / 100;
    }
    return (
        <FormControl fullWidth>
            {
                !!field.label && (
                    <InputLabel shrink variant="multiline-label">
                        {renderLabel(field.label, field.tooltip)}
                    </InputLabel>
                )
            }
            {field.validation === "none" && (
                <NoValidationInput
                    field={field}
                    type={field.type}
                    defaultValue={value}
                    disabled={!field.editableByClient || disabled}
                    onChange={(values) => { handleChange(values) }}
                    onBlur={handleBlur}
                    onFocus={onFocus}
                />
            )}
            {field.validation === "numerical" && (
                <CLANumberField
                    label={renderLabel(field.label, field.tooltip)}
                    value={value}
                    placeholder={field.placeholder}
                    isInstance
                    notched
                    digitGroupSeparator=","
                    onChange={(_, newValue) => { handleChange(newValue) }}
                    field
                    disabled={!field.editableByClient || disabled}
                    onBlur={handleBlur}
                    onFocus={onFocus}
                />
            )}
            {field.validation === "usCurrency" && (
                <CLACurrencyField
                    label={renderLabel(field.label, field.tooltip)}
                    value={value}
                    placeholder={field.placeholder}
                    notched
                    isInstance
                    field
                    onChange={(_, newValue) => { handleChange(newValue) }}
                    disabled={!field.editableByClient || disabled}
                    onBlur={handleBlur}
                    onFocus={onFocus}
                />
            )}
            {field.validation === "percentage" && (
                <CLAPercentageField
                    label={renderLabel(field.label, field.tooltip)}
                    value={fieldValue}
                    placeholder={field.placeholder}
                    notched
                    isInstance
                    disabled={!field.editableByClient || disabled}
                    onBlur={handleBlur}
                    onChange={(_, newValue) => { handleChange(newValue) }}
                    onFocus={onFocus}
                />
            )}
        </FormControl>
    );
});

export const Field = ({ field, index, handleOnBlur }) => {

    const { control, getValues, setValue } = useFormContext()
    const { answers, linkDefaultList, isQuestionGroupFocused, actions: { updateIsQuestionGroupFocused } } = useProjectFormContext()
    const { shouldDisplay } = useRules(field.rules);

    const {
        isLocked,
        lockedField,
        lockingUser,
        emitLockEvent,
        emitUnlockEvent,
        emitUpdateEvent,
        concurrentValue,
    } = useProjectFormConcurrentLocking(field.id);

    const formValue = useWatch({ control, name: field.id });
    const disabled = field.linkType ? field.linkType === 'Read Only' : !!(lockingUser?.userId)
    const isIdle = useIdle(WAITING_TIME.MINS_13);

    const getDefaultValue = (multipleValues = false) => {
        const answerLinkDefault = linkDefaultList?.find((item) => item.id === field.id);
        const id =
            answerLinkDefault && !answerLinkDefault?.isUserModified && answerLinkDefault?.existingQuestionId
                ? field.existingQuestionId
                : field.id;

        if (multipleValues) {
            return field.defaultValues?.length > 0 &&
                field.defaultValues.every((item) => item instanceof String)
                ? field.defaultValues
                : [''];
        }

        const df = getValues(id)
        return df
    }

    //Added isRadioOnly field to prevent introducing new bugs
    const handleBlur = (e, isRadioOrCheckBox, isRadioOnly = false) => {
        handleOnBlur();

        let answer = null;
        if (field.type === FIELD_TYPES.SHORT_ANSWER || field.type === FIELD_TYPES.LONG_ANSWER || field.type === FIELD_TYPES.DATE_PICKER || field.type === FIELD_TYPES.DROPDOWN) {
            answer = getDefaultValue();
        } else if (isRadioOrCheckBox) {
            answer = formValue
        } else if (e || isRadioOnly) {
            answer = e;
        } else {
            answer = formValue
        }
        const allowEmptyString = isRadioOnly;
        emitUpdateEvent(answer, allowEmptyString, () => { emitUnlockEvent(answer, false, allowEmptyString) });
    }

    const handleFocus = () => {
        emitLockEvent()
    }

    useEffect(() => {
        if (isIdle) {
            const delay = setTimeout(() => {
                if (lockedField === field.id)
                    emitUnlockEvent(null, true)
            }, WAITING_TIME.MINS_2);

            return () => clearTimeout(delay)
        }
    }, [isIdle, lockedField, field])


    useUpdateEffect(() => {
        if (concurrentValue || (!concurrentValue && field.type !== FIELD_TYPES.QUESTION_GROUP)) {
            setValue(field.id, concurrentValue)
        }
    }, [concurrentValue])

    const renderLink = () => {
        return <Link questionId={field.id} />;
    }

    if ((!shouldDisplay || !field.visibleToClient) && field.type !== FIELD_TYPES.UNDERSTANDING_OF_ENTITY) {
        return null
    }

    switch (field.type) {
        case FIELD_TYPES.SHORT_ANSWER:
        case FIELD_TYPES.LONG_ANSWER:
            return (
                <div key={index} className={styles["vf-rendered-field-wrapper"]} style={{ width: `${field.width}%` }}>
                    <FieldWrapper lockingUser={lockingUser} isLocked={isLocked}>
                        <div className={styles["vf-rendered-field"]}>
                            <div className={styles["field__wrapper"]}>
                                <Controller
                                    key={field.id}
                                    name={field.id}
                                    control={control}
                                    render={({ field: { onChange } }) => (
                                        <ProjectFormTextFields
                                            field={field}
                                            handleChange={onChange}
                                            handleBlur={handleBlur}
                                            value={getDefaultValue()}
                                            onFocus={handleFocus}
                                            disabled={disabled}
                                        />
                                    )}
                                />
                                {renderLink()}
                            </div>
                        </div>
                    </FieldWrapper>
                </div>
            )
        case FIELD_TYPES.FREETEXT:
        case FIELD_TYPES.FREETEXT_NO_RULES:
            return (
                <div key={index} className={styles["vf-rendered-field-wrapper"]} style={{ width: `${field.width}%` }}>
                    <div className={styles["vf-rendered-field"]}>
                        <div className={styles["field__wrapper"]}>
                            <Flex direction="row" justify="between" align="baseline">
                                <Typography variant="freeText">
                                    <div
                                        className="ql-editor"
                                        dangerouslySetInnerHTML={{
                                            __html: sanitize(field.text, { ADD_ATTR: ['target'] }),
                                        }}
                                    />
                                </Typography>
                            </Flex>
                            {renderLink()}
                        </div>
                    </div>
                </div>
            )
        case FIELD_TYPES.RADIOGROUP:
            return (
                <div
                    key={index}
                    className={styles["vf-rendered-field-wrapper"]}
                    style={{ width: `${field.width}%` }}
                    onBlur={(e) => { if (!field.allowOtherOption) handleBlur(e, true) }}
                >
                    <FieldWrapper lockingUser={lockingUser} isLocked={isLocked}>
                        <div className={styles["vf-rendered-field"]}>
                            <Controller
                                key={field.id}
                                name={field.id}
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <CLARadioButtonList
                                        id={field.id}
                                        isDisabled={!field.editableByClient || disabled}
                                        label={renderLabel(field.label, field.tooltip, renderLink)}
                                        value={formValue}
                                        onChange={value => {
                                            onChange(value);
                                            if (field.allowOtherOption) handleBlur(value, false, true)
                                        }}
                                        required={field.required}
                                        onFocus={handleFocus}
                                        columns={field.columns}
                                        options={field.options}
                                        allowOtherOption={field.allowOtherOption}
                                    />
                                )}
                            />
                        </div>
                    </FieldWrapper>
                </div>
            )
        case FIELD_TYPES.DATE_PICKER:
            return (
                <div key={index} className={styles["vf-rendered-field-wrapper"]} style={{ width: `${field.width}%` }}>
                    <FieldWrapper lockingUser={lockingUser} isLocked={isLocked} >
                        <div className={styles["vf-rendered-field"]}>
                            <div className={styles["field__wrapper"]}>
                                <Controller
                                    key={field.id}
                                    name={field.id}
                                    control={control}
                                    render={({ field: { onChange } }) => (
                                        <CLADatePickerField
                                            id={field.id}
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e) => {
                                                const value = e ?? null;
                                                onChange(value);
                                            }}
                                            onBlur={() => {
                                                handleBlur();
                                            }}
                                            disabled={!field.editableByClient || disabled}
                                            placeholder={field.placeholder}
                                            defaultValue={formValue}
                                            onFocus={handleFocus}
                                            allowMultiLineLabel
                                        />
                                    )}
                                />
                                {renderLink()}
                            </div>
                        </div>
                    </FieldWrapper>
                </div>
            );
        case FIELD_TYPES.DROPDOWN:
            return (
                <div key={index} className={styles["vf-rendered-field-wrapper"]} style={{ width: `${field.width}%` }}>
                    <FieldWrapper lockingUser={lockingUser} isLocked={isLocked}>
                        <div className={styles["vf-rendered-field"]}>
                            <div className={styles["field__wrapper"]}>
                                <Controller
                                    key={field.id}
                                    name={field.id}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            {field.allowOtherOption && (
                                                <CLAComboBox
                                                    label={renderLabel(field.label, field.tooltip)}
                                                    defaultValue={formValue}
                                                    onChange={(values) => {
                                                        onChange(values);
                                                    }}
                                                    onBlur={(values) => {
                                                        handleBlur(values);
                                                        emitUnlockEvent(values, false, false);
                                                    }}
                                                    onFocus={handleFocus}
                                                    isDisabled={!field.editableByClient || disabled}
                                                    options={field.options}
                                                    allowMultiSelect={field.allowMultiSelect}
                                                    allowMultiLineLabel
                                                />
                                            )}
                                            {!field.allowOtherOption && !field.allowMultiSelect && (
                                                <CLADropdown
                                                    name={field.id}
                                                    label={renderLabel(field.label, field.tooltip)}
                                                    options={field.options}
                                                    value={formValue}
                                                    isDisabled={!field.editableByClient || disabled}
                                                    onChange={(values) => {
                                                        onChange(values);
                                                        handleBlur(values)
                                                    }}
                                                    onClose={() => {
                                                        emitUnlockEvent(getDefaultValue(), false, false)
                                                    }}
                                                    onOpen={() => {
                                                        handleFocus()
                                                    }}
                                                    allowMultiLineLabel
                                                />
                                            )}
                                            {!field.allowOtherOption && field.allowMultiSelect && (
                                                <CLASelect
                                                    id={field.id}
                                                    name={field.id}
                                                    label={renderLabel(field.label, field.tooltip)}
                                                    isDisabled={!field.editableByClient || disabled}
                                                    defaultValues={
                                                        Array.isArray(formValue) ? formValue.filter(Boolean) : [formValue].filter(Boolean)
                                                    }
                                                    onChange={(values) => {
                                                        onChange(values?.filter((i) => i));
                                                    }}
                                                    onClose={() => {
                                                        handleBlur(getDefaultValue()?.filter((i) => i))
                                                    }}
                                                    onOpen={handleFocus}
                                                    menuItems={field.options}
                                                    allowMultiLineLabel
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                {renderLink()}
                            </div>
                        </div>
                    </FieldWrapper>
                </div>
            )
        case FIELD_TYPES.TABLE:
            const parsed = parseQuestionLabel(field.label);
            const label = typeof parsed === 'object' ? parsed.questionLabel : parsed;
            return (
                <div key={index} className={styles["vf-rendered-field-wrapper"]} style={{ width: `${field.width}%` }}>
                    <FieldWrapper lockingUser={lockingUser} isLocked={isLocked}>
                        <div className={styles["vf-rendered-field"]}>
                            <div className={styles['vf-section-table-container']}>
                                <div className={styles['vf-section-table-label-wrapper']}>
                                    <Flex direction="row" justify="between" align="baseline">
                                        <InputLabel className={styles['vf-section-table-label']}>
                                            {renderLabel(label, field.tooltip)}
                                        </InputLabel>
                                    </Flex>
                                </div>
                                <Controller
                                    key={field.id}
                                    name={field.id}
                                    control={control}
                                    render={({ field: { onChange } }) => (
                                        <CLAFormTable
                                            field={field}
                                            values={formValue}
                                            disabled={!field.editableByClient || disabled}
                                            onChange={(e) => {
                                                onChange(e);
                                                handleBlur(e);
                                            }}
                                            onFocus={handleFocus}
                                            isInstance
                                        />
                                    )} />
                            </div>
                        </div>
                    </FieldWrapper>
                </div>
            );
        case FIELD_TYPES.CHECKBOXGROUP:
            return (
                <div key={index} className={styles["vf-rendered-field-wrapper"]} style={{ width: `${field.width}%` }} onBlur={(e) => handleBlur(e, true)}>
                    <FieldWrapper lockingUser={lockingUser} isLocked={isLocked}>
                        <Controller
                            key={field.id}
                            name={field.id}
                            control={control}
                            render={({ field: { onChange } }) => (
                                <CLACheckBoxGroup
                                    id={field.id}
                                    label={renderLabel(field.label, field.tooltip, renderLink)}
                                    value={Array.isArray(formValue) ? formValue.filter(Boolean) : [formValue].filter(Boolean)}
                                    onChange={(value, otherValue, otherToggled) => {
                                        const copy = [...value];
                                        if (otherValue) copy.push(otherValue);

                                        onChange(copy);
                                        if (!otherToggled) handleOnBlur();
                                    }}
                                    isDisabled={!field.editableByClient || disabled}
                                    required={field.required}
                                    columns={field.columns}
                                    options={field.options}
                                    allowOtherOption={field.allowOtherOption}
                                    onFocus={handleFocus}
                                    isInstance
                                />
                            )}
                        />
                    </FieldWrapper>
                </div>
            );
        case FIELD_TYPES.FORMULA: {
            return (
                <div key={index} className={styles["vf-rendered-field-wrapper"]} style={{ width: `${field.width}%` }}>
                    <CLAFormula
                        field={field}
                        shouldRenderFormula={true}
                        label={renderLabel(field.label, field.tooltip)}
                        answerList={answers ? copyObject(answers) : null}
                    />
                </div>
            )
        }
        case FIELD_TYPES.QUESTION_GROUP:
            return (
                <div key={index} className={styles["vf-rendered-field-wrapper"]} style={{ width: `${field.width}%` }}>
                    <FieldWrapper lockingUser={lockingUser} isLocked={isLocked}>
                        <Controller
                            name={field.id}
                            control={control}
                            render={({ field: { onChange } }) => (
                                <VFQuestionGroup
                                    field={field}
                                    isInstance
                                    disabled={!field.editableByClient || disabled}
                                    onChange={(value, isOnBlur) => {
                                        onChange(value)
                                        if (isQuestionGroupFocused && isOnBlur) handleBlur(value)
                                    }}
                                    onDropDownClose={() => {
                                        const answer = getDefaultValue();
                                        emitUnlockEvent(answer, false, false);
                                    }}
                                    handleOnFocus={handleFocus}
                                    answerValues={formValue}
                                    updateIsFormFocused={updateIsQuestionGroupFocused}
                                    onSubmit={() => { }} // No need to call handleSubmit as it is being called when calling handleBlur in onChange
                                />
                            )}
                        />
                    </FieldWrapper>
                </div>
            );
        case FIELD_TYPES.UNDERSTANDING_OF_ENTITY: {
            return (
                <div className={styles["vf-rendered-field-wrapper"]} style={{ width: '100%' }}>
                    <div className={styles["vf-rendered-field"]}>
                        <UnderstandingOfEntityProvider>
                            <UOEAuditAreaTable />
                        </UnderstandingOfEntityProvider>
                    </div>
                </div>
            );
        }
        default: {
            return null
        }
    }
}